import * as React from 'react';
import { graphql } from 'gatsby';
import Hero from '../components/hero';
import PortableText from '../components/portableText';
import Seo from '../components/seo';

export const query = graphql`
  query HomePageQuery {
    page: sanityPage(_id: { regex: "/(drafts.|)home/" }) {
      id
      title
      _rawBody
      heroImage {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
      }
    }
  }
`;

// markup
export default function IndexPage(props) {
  const { data, errors } = props;
  const page = data && data.page;
  return (
    <>
      <Seo title={page.title} />
      <main>
        <Hero hero={page.heroImage} title="" />
        <section>
          <PortableText blocks={page._rawBody || []} />
        </section>
      </main>
    </>
  );
}
